var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form",on:{"submit":function($event){$event.preventDefault();return _vm.setCompany.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('name_brand')}},[_c('validation-provider',{attrs:{"name":_vm.$t('name_brand'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('name_brand')},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('size_company')}},[_c('validation-provider',{attrs:{"name":_vm.$t('size_company'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.$t('options'),"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.employees_number),callback:function ($$v) {_vm.$set(_vm.formData, "employees_number", $$v)},expression:"formData.employees_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('position')}},[_c('validation-provider',{attrs:{"name":_vm.$t('position'),"vid":"posicion","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{class:{'is-invalid': !!errors.length},attrs:{"id":"position","name":_vm.$t('position'),"state":errors.length > 0 ? false:null,"options":_vm.$t('positions')},model:{value:(_vm.formData.position),callback:function ($$v) {_vm.$set(_vm.formData, "position", $$v)},expression:"formData.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('telephone')}},[_c('validation-provider',{attrs:{"name":_vm.$t('telephone'),"rules":"required"}},[_c('input-tel-brandme',{attrs:{"number_dad":_vm.formData.telephone},on:{"number":_vm.updateTelephone}})],1),_c('validation-provider',{attrs:{"name":_vm.$t('telephone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"hidden_phone",staticStyle:{"height":"0","width":"0","overflow":"hidden","padding":"0","border":"0"},model:{value:(_vm.formated_phone),callback:function ($$v) {_vm.formated_phone=$$v},expression:"formated_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('hr'),_c('b-button',{attrs:{"disabled":_vm.clicked || invalid || !_vm.formData.state,"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('register_company'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }