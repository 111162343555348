<template>

    <!-- form -->
    <validation-observer
    ref="form"
    #default="{invalid}"
    >
      <b-form
      class="auth-register-form"
      @submit.prevent="setCompany"
      >
        <b-row>
          <b-col class="col-12">
            <b-form-group
            :name="$t('telephone')"
            :label="$t('telephone_and_whats')"
            >
              <validation-provider
                :name="$t('telephone')"
              >
                <!-- <VuePhoneNumberInput
                        @update="countryPhone"
                        v-model="formData.telephone"
                        :preferred-countries="['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US']"
                        :show-code-on-list="true"
                        :translations="{
                          countrySelectorLabel: $t('countryLabel'),
                          countrySelectorError: $t('countryError'),
                          phoneNumberLabel:
                        default-country-code="MX"
                        :no-example="true"
                        :no-validator-state="true"
                        :error="errorPhone" /> -->
                <input-tel-brandme
                  :number_dad="formData.telephone"
                  @number="updateTelephone"
                ></input-tel-brandme>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <b-form-group
              :label="$t('your_content')">
              <b-form-textarea
                id="textarea"
                v-model="formData.question_text"
                :placeholder="$t('write_Message')"
                rows="3"
                :state="formData.question_text.length < 20 ? false : ''"
                
              />
              <small class="text-danger" v-if="formData.question_text.length < 20">{{ $t('creator.20characteres') }}</small>
            </b-form-group>
          </b-col>
          <b-col>
            <hr>
            <br>
            <b-button
              variant="primary"
              block
              :disabled="clicked || invalid || !formData.state || formData.question_text.length < 20"
              type="submit"
            >
              {{$t('save_info')}}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

</template>

<script>
import {
  BFormTextarea, BFormGroup, BRow, BCol, BButton, BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, length } from '@validations'
import service from '@/services/others'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import InputTelBrandme from '../components/inputs/InputTelBrandme.vue'

export default {
  components: {
    BFormTextarea,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
    InputTelBrandme
  },
  created () {
    localize('es')
    const self = this
    setTimeout(function() {
      self.$refs.hidden_phone.focus()
    }, 500)
  },
  data () {
    return {
      errorPhone: false,
      clicked: false,
      formData: {
        telephone: '',
        country: '',
        question_text: '',
        state: false,
      },
      required,
      length,
    }
  },
  methods: {
    setCompany () {
      this.clicked = true;
      this.$refs.form.validate()
        .then(valid => {
          if (valid) {
            service.formCreator(this.formData)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('register.saved'),
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: this.$t('register.saved')
                  }
                })
                this.$emit('next')
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'error al guardar',
                    icon: 'AlertIcon',
                    variant: 'danger',
                    text: 'Ocurrio un error al guardar.'
                  }
                })
              })
          } else this.clicked = false;
        })
    },
    updateTelephone(telephone_data) {
      this.formData.telephone = telephone_data.number;
      this.formData.state = telephone_data.state;
      this.formData.country = telephone_data.country;
    },
  }
}
</script>

<style>
  .app-content {
    padding-top: calc(10rem) !important;
  }
</style>
